import React from "react";

function article_1() {
	return {
		date: "8 August 2024",
		title: "The Rise of Quantum Computing",
		description:
			"Quantum computing is set to revolutionize industries with its unprecedented processing power. Explore how this cutting-edge technology is shaping the future.",
		keywords: [
			"The Rise of Quantum Computing",
			"Preet",
			"Chandak",
			"Preet Chandak",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid blue;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						Quantum computing is not just a buzzword; it represents a monumental shift in how we approach problem-solving in various industries. Unlike classical computers that process information in bits (0s and 1s), quantum computers use quantum bits or qubits, which can exist in multiple states simultaneously. This capability enables quantum computers to perform complex calculations at speeds unimaginable with current technology.
					</div>
					<div className="paragraph">
						One of the most promising applications of quantum computing is in the field of cryptography, where it has the potential to break existing encryption methods, forcing a rethink of how we secure data. Additionally, quantum computing is expected to revolutionize fields such as drug discovery, by simulating molecular structures to find new treatments more efficiently, and artificial intelligence, by vastly improving machine learning algorithms.
					</div>
					<div className="paragraph">
						Major tech companies, including IBM, Google, and Microsoft, are leading the charge in developing quantum computers, with each making significant strides in both hardware and software. As these technologies continue to evolve, they will undoubtedly open up new frontiers in computing, making the impossible possible.
					</div>
					{/* <img
						src="https://picsum.photos/200/300"
						alt="quantum-computing"
						className="randImage"
					/> */}
				</div>
			</React.Fragment>
		),
	};
}


function article_2() {
	return {
		date: "8 August 2024",
		title: "The Impact of 5G on Internet of Things (IoT)",
		description:
			"The rollout of 5G networks is accelerating the growth of IoT. Learn how this next-gen connectivity is enabling smarter cities, autonomous vehicles, and more.",
		keywords: [
			"Impact of 5G on IoT",
			"Preet",
			"Chandak",
			"Preet Chandak",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
				.iotImage {
					align-self: center;
					outline: 2px solid green;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						The deployment of 5G networks is a game-changer for the Internet of Things (IoT). With its high-speed, low-latency connectivity, 5G enables the seamless integration of a vast number of IoT devices, ranging from smart home appliances to autonomous vehicles. This new level of connectivity supports real-time data exchange, making IoT applications more efficient and reliable.
					</div>
					<div className="paragraph">
						One of the most significant impacts of 5G on IoT is in the realm of smart cities. With 5G, cities can implement more sophisticated monitoring systems for traffic management, energy consumption, and public safety. For instance, smart traffic lights that communicate with each other to optimize traffic flow, or sensors that detect and manage energy usage in buildings, are becoming more feasible with the advent of 5G.
					</div>
					<div className="paragraph">
						5G is also crucial for the development of autonomous vehicles, which rely on real-time data from sensors and other vehicles to navigate safely. The ultra-reliable, low-latency communication provided by 5G ensures that these vehicles can make split-second decisions, reducing the risk of accidents and improving overall traffic efficiency.
					</div>
					{/* <img
						src="https://picsum.photos/200/300"
						alt="5g-iot"
						className="iotImage"
					/> */}
				</div>
			</React.Fragment>
		),
	};
}


const myArticles = [article_1, article_2];

export default myArticles;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Moon, Sun } from "lucide-react";

import "./styles/navBar.css";

const NavBar = (props) => {
  const { active } = props;
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme || "light";
  });

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <React.Fragment>
      <div className="nav-container">
        <nav className="navbar">
          <div className="nav-background">
            <ul className="nav-list">
              <li className={active === "home" ? "nav-item active" : "nav-item"}>
                <Link to="/">Home</Link>
              </li>
              <li className={active === "about" ? "nav-item active" : "nav-item"}>
                <Link to="/about">About</Link>
              </li>
              <li className={active === "projects" ? "nav-item active" : "nav-item"}>
                <Link to="/projects">Projects</Link>
              </li>
              <li className={active === "articles" ? "nav-item active" : "nav-item"}>
                <Link to="/articles">Articles</Link>
              </li>
              <li className={active === "contact" ? "nav-item active" : "nav-item"}>
                <Link to="/contact">Contact</Link>
              </li>
              <li className="nav-item theme-toggle">
                <button onClick={toggleTheme} className="theme-toggle-button">
                  {theme === "light" ? <Moon size={16} /> : <Sun size={16} />}
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default NavBar;
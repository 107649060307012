import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./styles/article.css";

const Article = ({ title, description, date, link }) => {
	return (
		<div className="homepage-article">
			<div className="homepage-article-content">
				<div className="homepage-article-date">{date}</div>
				<h2 className="homepage-article-title">{title}</h2>
				<p className="homepage-article-description">{description}</p>
				<div className="homepage-article-link">
					<Link to={link}>
						Read article{" "}
						<FontAwesomeIcon className="link-icon" icon={faChevronRight} />
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Article;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { faMailBulk, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faGithub, faInstagram } from "@fortawesome/free-brands-svg-icons";

import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import Article from "../components/homepage/article";
import Works from "../components/homepage/works";
import AllProjects from "../components/projects/allProjects";
// import CelebrationPopup from "../components/CelebrationPopup"; // Import the new component


import INFO from "../data/user";
import SEO from "../data/seo";
import myArticles from "../data/articles";

import "./styles/homepage.css";

const Homepage = () => {
    const [logoSize, setLogoSize] = useState(80);
    const [stayLogo, setStayLogo] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            const newSize = 80 - (scrollPosition * 4) / 10;

            if (newSize <= 40) {
                setStayLogo(true);
                setLogoSize(40);
            } else {
                setStayLogo(false);
                setLogoSize(newSize);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const currentSEO = SEO.find((item) => item.page === "home");

    const logoStyle = {
        display: "flex",
        position: stayLogo ? "fixed" : "relative",
        top: stayLogo ? "3vh" : "auto",
        zIndex: 999,
        borderRadius: stayLogo ? "50%" : "none",
        boxShadow: stayLogo ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none",
        transition: "all 0.3s ease",
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{INFO.main.title}</title>
                <meta name="description" content={currentSEO.description} />
                <meta name="keywords" content={currentSEO.keywords.join(", ")} />
            </Helmet>
            <div className="page-content no-overflow">
            
                <NavBar active="home" />
                <div className="content-wrapper">
                    <div className="homepage-logo-container">
                        <div style={logoStyle}>
                            <Logo width={logoSize} link={false} />
                        </div>
                    </div>

                    <div className="homepage-container">
                        <div className="homepage-first-area">
                            <div className="homepage-first-area-left-side">
                                <div className="title homepage-title">
                                    {INFO.homepage.title}
                                </div>

                                <div className="subtitle homepage-subtitle">
                                    {INFO.homepage.description}
                                </div>
                            </div>

                            <div className="homepage-first-area-right-side">
                                <div className="homepage-image-container">
                                    <div className="homepage-image-wrapper">
                                        <img
                                            src="homepage.jpg"
                                            alt="about"
                                            className="homepage-image"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="homepage-socials">
                            <a href={INFO.socials.linkedin} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faLinkedinIn} className="homepage-social-icon" />
                            </a>
                            <a href={INFO.socials.github} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faGithub} className="homepage-social-icon" />
                            </a>
                            <a href={INFO.socials.instagram} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faInstagram} className="homepage-social-icon" />
                            </a>
                            <a href={`mailto:${INFO.main.email}`} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faMailBulk} className="homepage-social-icon" />
                            </a>
                            <a href="./Preet_Chandak.pdf" download className="resume-download-link">
                                <b>Resume</b> <FontAwesomeIcon icon={faDownload} />
                            </a>
                        </div>

                        <div className="homepage-projects">
                            <AllProjects />
                        </div>

                        <div className="homepage-after-title">
                            <div className="homepage-articles">
                                {myArticles.map((article, index) => (
                                    <div className="homepage-article" key={(index + 1).toString()}>
                                        <Article
                                            key={(index + 1).toString()}
                                            date={article().date}
                                            title={article().title}
                                            description={article().description}
                                            link={"/article/" + (index + 1)}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div className="homepage-works">
                                <Works />
                            </div>
                        </div>

                        <div className="page-footer">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            
        </React.Fragment>
    );
};

export default Homepage;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/contact.css";

const Contact = () => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		emailjs
			.sendForm(
				"service_8moslox",
				"template_33bkl55",
				e.target,
				"hdqEpl9bAjLyWQ449"
			)
			.then(
				(result) => {
					console.log(result.text);
					alert("Message sent successfully!");
				},
				(error) => {
					console.log(error.text);
					alert("Failed to send the message, please try again.");
				}
			);
		setFormData({
			name: "",
			email: "",
			message: "",
		});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "contact");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Contact | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta name="keywords" content={currentSEO.keywords.join(", ")} />
			</Helmet>

			<div className="page-content">
				<NavBar active="contact" />
				<div className="content-wrapper">
					<div className="contact-logo-container">
						<div className="contact-logo">
							<Logo width={38} />
						</div>
					</div>

					<div className="contact-container">
						<div className="title contact-title">
							Let's Get in Touch: Ways to Connect with Me
						</div>

						<div className="contact-subtitle">
							Thank you for your interest in getting in touch with me. I welcome your feedback, questions, and suggestions. If you have a specific question or comment, please feel free to email me directly at
							<a href={`mailto:${INFO.main.email}`}> {INFO.main.email}</a>. 
							I make an effort to respond to all messages within 24 hours, although it may take me longer during busy periods. Alternatively, you can use the contact form on my website to get in touch. Simply fill out the required fields and I'll get back to you as soon as possible. Finally, if you prefer to connect on social media, you can find me on
							<a href={INFO.socials.instagram} target="_blank" rel="noreferrer"> {INFO.socials.instagram}</a>. 
							I post regular updates and engage with my followers there, so don't hesitate to reach out. Thanks again for your interest, and I look forward to hearing from you!
						</div>
					</div>
					
					<div className="socials-container">
						<div className="form-container">
							
							<form className="contact-form" onSubmit={handleSubmit}>
							<h1 className="form-title">Get In Touch With Me!</h1>
								<input
									type="text"
									name="name"
									placeholder="Your Name"
									value={formData.name}
									onChange={handleChange}
									required
								/>
								<input
									type="email"
									name="email"
									placeholder="Your Email"
									value={formData.email}
									onChange={handleChange}
									required
								/>
								<textarea
									name="message"
									placeholder="Your Message"
									value={formData.message}
									onChange={handleChange}
									required
								></textarea>
								<button type="submit">Send Message</button>
							</form>
						</div>
						<div className="contact-socials">
							<Socials />
						</div>

					</div>

					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Contact;

// import React from "react";
// import { Link } from "react-router-dom";

// import "./styles/footer.css";

// const Footer = () => {
// 	return (
// 		<React.Fragment>
// 			<div className="footer">
// 				<div className="footer-links">
// 					<ul className="footer-nav-link-list">
// 						<li className="footer-nav-link-item">
// 							<Link to="/">Home</Link>
// 						</li>
// 						<li className="footer-nav-link-item">
// 							<Link to="/about">About</Link>
// 						</li>
// 						<li className="footer-nav-link-item">
// 							<Link to="/projects">Projects</Link>
// 						</li>
// 						<li className="footer-nav-link-item">
// 							<Link to="/articles">Articles</Link>
// 						</li>
// 						<li className="footer-nav-link-item">
// 							<Link to="/contact">Contact</Link>
// 						</li>
// 					</ul>
// 				</div>

// 				<div className="footer-credits">
// 					<div className="footer-credits-text">
// 						© 2024 Preet Chandak All Rights Reserved.
// 					</div>
// 				</div>
// 			</div>
// 		</React.Fragment>
// 	);
// };

// export default Footer;


import React from "react";
// import { Link } from "react-router-dom";

import "./styles/footer.css";
import INFO from "../../data/user";

const Footer = () => {
	return (
		<React.Fragment>
			<div className="footer">
				<div className="footer-social-media">
					<a href={INFO.socials.instagram}target="_blank" rel="noopener noreferrer">Instagram</a>
					<a href={INFO.socials.linkedin} target="_blank" rel="noopener noreferrer">LinkedIn</a>
					<a href={INFO.socials.github} target="_blank" rel="noopener noreferrer">GitHub</a>
				</div>
				
				<div className="footer-credits">
					<div className="footer-credits-text">
						© 2024 Preet Chandak All Rights Reserved.
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Footer;

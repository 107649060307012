const INFO = {
	main: {
		title: "Preet Chandak",
		name: "Preet Chandak",
		email: "preet2828chandak@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		// twitter: "https://twitter.com/",
		github: "https://www.instagram.com/preetchandak269/",
		linkedin: "https://www.linkedin.com/in/preet-chandak/",
		instagram: "https://instagram.com/preetchandak269",
	},

	homepage: {
		title: "Full-Stack Web Developer and Amateur Astrophile.",
		description:
			"I am a backend developer with expertise in Node.js and RESTful APIs. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "About Me",
		description:
			"Hello there! I'm Preet Chandak, a passionate programmer with expertise in a variety of languages including Golang, C++, C, Python, and Java. My journey as a computer enthusiast has led me to explore a wide range of technologies and frameworks. I specialize in JavaScript, HTML, and CSS for crafting dynamic websites and have a strong command of MongoDB and MySQL for database management.<br/><br/>I leverage frameworks like React.js, Node.js, and Express.js to build robust web applications. My toolkit also includes OpenCV, Numpy, and Flask for developing innovative solutions. Additionally, with experience in AWS services such as S3, EC2, and RDS, I architect scalable cloud solutions.<br/> <br/>Beyond technology, music fuels my creativity and problem-solving abilities. I am also a skilled communicator, adept at explaining complex ideas clearly through well-honed presentation skills. Let's connect and dive into captivating conversations, whether about tech, music, or any other intriguing topic. I'm always eager for new opportunities and exchanges!",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Advanced Web Scraper : Chrome Extension",
			description:
				"JavaScript, Flask, Playwright, BeautifulSoup, Chrome Extensions",
			logo: "https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png",
			linkText: "View Project",
			link: "https://github.com/Preet-Chandak/Advanced-Web-Scraper-Chrome-Extension",
		},
		{
			title: "Hostel Leave Management System",
			description:
				"PostgreSQL, ReactJS, NodeJS, ExpressJS, Prisma Client, Redis, Edge Functions Supabase",
			logo: "https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg",
			linkText: "View Project",
			link: "https://github.com/Preet-Chandak/Secure-Exam-Paper",
		},
		{
			title: "Secure Examination Paper Distribution System",
			description:
				"ReactJS, MongoDB, NodeJS, ExpressJS, Amazon S3",
			logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlGmKtrnxElpqw3AExKXPWWBulcwjlvDJa1Q&s",
			linkText: "View Project",
			link: "https://github.com/Preet-Chandak/Secure-Exam-Paper",
		},

		{
			title: "Type-Tangle : Collaborative Programming Platform",
			description:
				"MongoDB, ExpressJS, ReactJS, NodeJS, SocketIO, AWS, Code-Mirror",
			logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/885px-Node.js_logo.svg.png?20170401104355",
			linkText: "View Project",
			link: "https://github.com/Preet-Chandak/Type-Tangle-Code-Karo-Sath-main",
		},

		{
			title: "Lifestyle Products E-Commerce Wordpress Website ",
			description:
				"Used Wordpress and React-Press plugin for e-commerce website",
			logo: "https://www.logo.wine/a/logo/WordPress.com/WordPress.com-Logo.wine.svg",
			linkText: "View Project",
			link: "https://rukirui.in",
		},

		{
			title: "3-Tier Architecture Application Developing using Docker",
			description:
				"My project implements a 3-tier architecture using Docker containers",
			logo: "https://i.pinimg.com/originals/0e/5a/ea/0e5aea52771d1897850b0a5f6547cc35.jpg",
			linkText: "View Project",
			link: "https://github.com/Preet-Chandak/Docker-3-Tier-MySQL",
		},

		{
			title: "Reverse DCF Dashboard : Using Python Dash",
			description:
				"Used Python Dash, BeautifulSoup for data scraping, Plotly, Pandas, ",
			logo: "https://p1.hiclipart.com/preview/835/530/416/python-logo-programming-language-computer-programming-python-programming-basics-for-absolute-beginners-scripting-language-source-code-php-code-climate-inc-png-clipart.jpg",
			linkText: "View Project",
			link: "https://github.com/Preet-Chandak/Reverse-DCF-Data-Analysis",
		},

		{
			title: "Role Based : Library Management System",
			description:
				"MongoDB, ExpressJS, ReactJS, NodeJS, JWT Tokens, RESTful APIs ",
			logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlGmKtrnxElpqw3AExKXPWWBulcwjlvDJa1Q&s",
			linkText: "View Project",
			link: "https://github.com/Preet-Chandak/LibraryManagementSystem",
		},
	],
};

export default INFO;